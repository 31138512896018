import { NextImage } from "@jog/react-components";
export const CountryLink = ({ children, href, isUSCountry, hideModal, }) => {
    return (<>
            {isUSCountry ? (<div className="relative inline-block w-full cursor-pointer break-words pl-4 text-13 text-black no-underline hover:no-underline" onClick={hideModal}>
                    <NextImage className="absolute top-0 left-0 inline-block h-4 w-auto" blur={false} width={27} height={38} src="/images/black-bag.png" alt="country"/>
                    {children}
                </div>) : (<a className="relative inline-block w-full break-words pl-4 text-13 text-black no-underline hover:no-underline" href={href}>
                    <NextImage className="absolute top-0 left-0 inline-block h-4 w-auto" blur={false} width={27} height={38} src="/images/black-bag.png" alt="country"/>
                    {children}
                </a>)}
        </>);
};

import { Modal } from "@/components/Modal/Modal";
import PrimaryButton from "@/components/PrimaryButton/PrimaryButton";
import { useStores } from "@/stores";
import { getAllCountriesWithLanguages, getStoreURLByCountryCode } from "@/util/helperFunctions";
import { Link, useI18n } from "@jog/react-components";
import { getAvailableCountryCodes, getAvailableCountryCodesInUS } from "@jog/shared";
import { observer } from "mobx-react";
import React, { useMemo } from "react";
import { uid } from "react-uid";
import { CountryLink } from "./CountryLink";
const countryCodeToName = {
    AT: "AUSTRIA",
    BE: "BELGIUM",
    DE: "GERMANY",
    ES: "SPAIN",
    FR: "FRANCE",
    LU: "LUXEMBOURG",
    NL: "THE NETHERLANDS",
    US: "GLOBAL",
};
const CountryRedirectModal = observer(({ hideModal }) => {
    const { $t } = useI18n();
    const { userStore } = useStores();
    const country = process.env.NEXT_PUBLIC_COUNTRY || "";
    const storeCountryName = countryCodeToName[country];
    const getSuggestedCountryCode = (userCountryCode) => {
        const validCountryCodes = getAvailableCountryCodes();
        return validCountryCodes.includes(userCountryCode) ? userCountryCode : "US";
    };
    const userCountryCode = useMemo(() => userStore.userCountry, [userStore.userCountry]);
    const suggestedCountryCode = getSuggestedCountryCode(userCountryCode);
    const suggestedCountryName = countryCodeToName[suggestedCountryCode];
    const isUSCountry = country === "US";
    const countriesWithLanguages = getAllCountriesWithLanguages();
    const colCount = 3;
    const columns = [];
    const chunkSize = Math.ceil(countriesWithLanguages.length / colCount);
    for (let i = 0; i < colCount; i++) {
        columns.push(countriesWithLanguages.slice(i * chunkSize, i * chunkSize + chunkSize));
    }
    const getIsUSCountry = (code) => {
        const availableCountryCodesInUS = getAvailableCountryCodesInUS();
        return availableCountryCodesInUS.includes(code);
    };
    return (<Modal hideModal={hideModal} height="auto" modalClass="!min-w-[90%] xs:!min-w-[300px] !px-2 xs:px-5">
            <div className="flex flex-col content-center items-center py-0 xs:px-3.75">
                {!isUSCountry ? (<>
                        <h2 className="mb-5 pt-1 font-primary text-sm font-bold uppercase xs:text-26">{`${$t(`NOT IN`)} ${$t(storeCountryName)}?`}</h2>
                        <p className="m-0 text-center text-xs xs:text-sm">
                            {$t("You are about to buy products in another country. Please note that the purchase will not be available.")}
                        </p>
                        <p className="m-0 text-center text-sm">
                            {$t("We have a webshop in your country that you might prefer to shop instead.")}
                        </p>
                        <Link className="mt-2 xs:mt-7.5" href={getStoreURLByCountryCode(suggestedCountryCode)}>
                            <PrimaryButton>
                                {$t("CONTINUE")} {$t(suggestedCountryName)}
                            </PrimaryButton>
                        </Link>
                    </>) : (<>
                        <h2 className="p-1 font-primary text-sm font-bold uppercase xs:mb-5 xs:text-26">
                            {$t("Select country")}
                        </h2>
                        <div className="grid w-full grid-cols-1 grid-rows-3 gap-0 xs:grid-cols-3 xs:gap-2 lg:gap-3.75 lg:px-3.75">
                            {countriesWithLanguages.map((country, index) => (<div className="mb-0 xs:mb-2" key={uid(index)}>
                                    <CountryLink href={country.url} isUSCountry={getIsUSCountry(country.countryCode)} hideModal={hideModal}>
                                        {country.name}
                                    </CountryLink>
                                </div>))}
                        </div>
                        <p className="mt-1 text-center text-sm leading-tight xs:mt-8.75">
                            {$t("Please select your country and preferred language. When your country is not on the list, it is unfortunately not possible to place an order.")}
                        </p>
                    </>)}
            </div>
        </Modal>);
});
export default React.memo(CountryRedirectModal);

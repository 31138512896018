import PrimaryButton from "@/components/PrimaryButton/PrimaryButton";
import { GRichText } from "@/components/RichText/RichText";
import { I18n } from "@jog/react-components";
const ThankyouMessage = ({ hideModal, thankyouTitle, thankyouBody, }) => {
    return (<div className="text-center">
            <h2 className="uppercase"> {thankyouTitle}</h2>
            <p className="mt-5 mb-15 text-xs">
                <GRichText field={thankyouBody}/>
            </p>
            <PrimaryButton onClickFunction={hideModal}>
                <I18n t="OK"/>
            </PrimaryButton>
        </div>);
};
export default ThankyouMessage;
